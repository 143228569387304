<template>
  <div class="invoice">
    <Spinner :loading="invoice.loading" />
    <div class="inv-inner" v-for="inv in invoice.data.data" :key="inv.id">
      <!-- Invoice Header -->
      <div class="inv-header">
        <Icon class="h-6 w-auto" name="logo" v-if="inv.order.status == 'draft'" />
        <Icon class="h-6 w-auto" name="logo-invoice" v-else />
        <div>
          <div class="inv-number">
            <span>{{ inv.order.status == 'draft' ? '' : 'Invoice #' }}</span>
            <p v-if="inv.order.status != 'draft'">{{ inv.invoice_number }}</p>
          </div>
          <div class="inv-number" v-if="inv.order.reference_number">
            <span>Reference #</span>
            <p>{{ inv.order.reference_number }}</p>
          </div>
        </div>
      </div>
      <div class="inv-icon" :class="inv.packingList ? 'cursor-pointer' : ''">
        <span @click="packingList(inv)"
          ><Icon name="package-filled"
        /></span>
      </div>
      <div class="badges" v-if="inv.order.status != 'draft'">
        <div class="invoice-status" :class="inv.payment_status">
          <span>{{ inv.payment_status }}</span>
        </div>
      </div>

      <!-- Invoice Content -->
      <div class="inv-details">
        <div class="address">
          <div class="address-grid">
            <div>
              <span class="small">Bill to</span>
              <p>{{ inv.billingAddress.company_name }}</p>
              <span>
                <div>{{ inv.billingAddress.address1 }}</div>
                <div>{{ inv.billingAddress.address2 }}</div>
                <div>
                  {{ inv.billingAddress.city }}, {{ inv.billingAddress.state }}
                  {{ inv.billingAddress.zip_code }}
                </div>
              </span>
            </div>
            <div>
              <span class="small">Ship to</span>
              <p>
                {{
                  inv.shippingAddress.nickname
                    ? inv.shippingAddress.nickname
                    : inv.shippingAddress.company_name
                }}
              </p>
              <span>
                <div>{{ inv.shippingAddress.address1 }}</div>
                <div>{{ inv.shippingAddress.address2 }}</div>
                <div>
                  {{ inv.shippingAddress.city }},
                  {{ inv.shippingAddress.state }}
                  {{ inv.shippingAddress.zip_code }}
                </div>
              </span>
            </div>
          </div>
          <div v-if="inv.order.assignee">
            <span class="small">Representative</span>
            <p>
              {{ inv.order.assignee.name +' '+ inv.order.assignee.last_name }}
            </p>
            <span>
              <div>{{ inv.order.assignee.email }}</div>
              <div>{{ inv.order.assignee.phone_number }}</div>
            </span>
          </div>
        </div>
        <div class="address mt-7">
          <div>
            <div class="flex gap-7 justify-between">
              {{ inv.order.status != 'draft' ? 'Order Number' : 'Quote Number' }}
              <p>{{ inv.order.order_number }}</p>
            </div>
            <div class="flex gap-7 justify-between">
              {{ inv.order.status != 'draft' ? 'Order Date' : 'Quote Date' }}
              <p>{{ moment(inv.order.created_at) }}</p>
            </div>
            <div class="flex gap-7 justify-between" v-if="inv.order.status != 'draft' && inv.payment_status != 'paid'">
              Payment Due
              <p>{{ moment(inv.due_at) }}</p>
            </div>
          </div>
          <div class="pt-12 flex items-start justify-end hide-on-print">
            <button
              class="inline-flex items-center space-x-1 bg-theme-secondary text-white px-4 py-1.5 rounded leading-none"
              @click="downloadInvoice(inv.order.order_number)"
            >
              <DownloadIcon class="w-3.5 h-3.5" />
              <span>Download</span>
            </button>
          </div>
        </div>
      </div>

      <!-- List Items -->
      <div class="inv-list">
        <div class="inv-list-header">
          <div class="name">Item</div>
          <div class="price">Price</div>
          <div class="qty">Qty</div>
          <div class="total">Total</div>
        </div>
        <div class="inv-list-items">
          <template v-for="item in inv.invoice_line_items" :key="item.id">
            <div v-if="item.line_type == 'product'" class="inv-list-item">
              <div class="name">
                <img :src="item.image_url" :alt="item.variant_name" />
                <div class="item-info">
                  <p>{{ item.product_name }} - {{ item.variant_name }}</p>
                  <span>{{ item.sku }}</span>
                </div>
              </div>
              <!-- <div class="price">{{ Number(item.price).toFixed(2) }}</div> -->
              <div class="price">{{ Number(getPrice(item)).toFixed(2) }}</div>
              <div class="qty">{{ parseInt(item.quantity) }}</div>
              <div class="total">
                {{ currencyFormat(item.quantity * getPrice(item)) }}
              </div>
            </div>
          </template>
        </div>
        <div class="inv-pricing">
          <div class="tax-pricings">
            <div
              class="flex items-center space-x-2 mb-3"
              v-if="inv.order.TotalTax"
            >
              <Icon class="w-5 h-5 -mt-0.5" name="document-filled" />
              <span class="text-md font-medium">Tax Summary</span>
            </div>
            <div
              class="price-item"
              v-for="(tax, label) in inv.order.VariantTaxLabelsShow"
              :key="label"
            >
              <p>{{ label }}</p>
              <span>${{ commaFormat(Number(tax).toFixed(2)) }}</span>
            </div>
            <div class="price-item highlight" v-if="inv.order.TotalTax">
              <p>Total Taxes</p>
              <span
                >${{ commaFormat(Number(inv.order.TotalTax).toFixed(2)) }}</span
              >
            </div>
          </div>
          <div class="order-pricings">
            <div class="price-item">
              <p>Order Total</p>
              <span
                >${{ commaFormat(Number(inv.order.subTotal).toFixed(2)) }}</span
              >
            </div>
            <div v-if="inv.order.TotalTax" class="price-item">
              <p>Taxes</p>
              <span
                >${{ commaFormat(Number(inv.order.TotalTax).toFixed(2)) }}</span
              >
            </div>
            <div
              v-if="
                inv.invoice_line_items.filter((r) => r.line_type == 'shipping') &&
                inv.order.status != 'draft'
              "
              class="price-item"
            >
              <p>Shipping</p>
              <span
                >${{
                  Number(
                    inv.invoice_line_items.filter(
                      (r) => r.line_type == "shipping"
                    )[0].price
                  ).toFixed(2)
                }}</span
              >
            </div>
            <div class="price-item highlight">
              <p>Sub Total</p>
              <span
                v-if="inv.order.status != 'draft'"
                >${{
                  commaFormat(
                    Number(
                      parseFloat(
                        inv.invoice_line_items.filter(
                          (r) => r.line_type == "shipping"
                        )[0].price
                      ) +
                        parseFloat(inv.order.TotalTax) +
                        parseFloat(inv.order.subTotal)
                    ).toFixed(2)
                  )
                }}</span
              >
              <span
                v-else
                >${{
                  commaFormat(
                    Number(
                      parseFloat(inv.order.TotalTax) +
                        parseFloat(inv.order.subTotal)
                    ).toFixed(2)
                  )
                }}</span
              >
            </div>
            <template v-for="item in inv.invoice_line_items" :key="item.id">
              <div v-if="item.line_type == 'discount'" class="price-item">
                <p>{{ item.label }}</p>
                <span
                  >-${{
                    commaFormat(Number(Math.abs(item.price)).toFixed(2))
                  }}</span
                >
              </div>
            </template>
            <div class="price-item highlight pb-zero">
              <p>Total</p>
              <span
                >${{
                  commaFormat(Number(inv.order.OrderTotalPrice).toFixed(2))
                }}</span
              >
            </div>
            <div class="price-item highlight-noborder" v-if="inv.order.status != 'draft'">
              <p>Paid</p>
              <span>${{ commaFormat(Number(inv.PaidAmount).toFixed(2)) }}</span>
            </div>
            <div class="price-item highlight-noborder" v-if="inv.order.status != 'draft'">
              <p>Balance</p>
              <span
                >${{
                  commaFormat(
                    Number(
                      parseFloat(inv.order.OrderTotalPrice) -
                        parseFloat(inv.PaidAmount)
                    ).toFixed(2)
                  )
                }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="p-5 text-left space-y-3 border-2 border-gray-200 m-4">
        <p
          class="text-theme-secondary font-light text-xs"
          v-if="inv.invoiceText && inv.order.TotalTax"
        >
          {{ getContent(inv, inv.invoiceText.content) }}
        </p>
        <p
          class="text-theme-secondary font-light text-xs"
          v-if="inv.invoiceText && inv.order.TotalTax"
        >
          {{ getContent(inv, inv.invoiceText.open_system, "open") }}
        </p>
        <p
          class="text-theme-secondary font-light text-xs"
          v-if="inv.invoiceText && inv.order.TotalTax"
        >
          {{ getContent(inv, inv.invoiceText.close_system, "closed") }}
        </p>
        <p
          class="text-theme-secondary font-light text-xs"
          v-if="inv.invoiceText && inv.order.TotalTax"
        >
          {{ getContent(inv, inv.invoiceText.text, "text") }}
        </p>
        <p
          class="text-theme-secondary font-medium text-xs"
          v-if="inv.invoiceText"
        >
          {{ inv.invoiceText.license?.medusa_license_number }}
        </p>
        <p
          class="text-theme-secondary font-medium text-xs"
          v-if="inv.shippingAddress.state_code == 'VA' && inv.order.TotalTax == 0 && !inv.company.tags.includes('COLLECT')"
        >
          Virginia Tobacco Products Tax NOT Paid
        </p>
        <p class=" text-theme-dark-shade font-medium text-xs pt-2">
           {{ inv.order.status != 'draft' ? 'We appreciate your business.' : 'We appreciate Please note: this is a DRAFT ORDER and the inventory will not be reserved for you until you ask us to finalize this order. Thank you' }}
        </p>
      </div>
      <!-- <div class="inv-qr-code px-10 py-14">
        <div class="flex items-start">
          <img src="@/assets/images/qrcode.png" alt="" class="w-24 h-24" />
          <div class="flex flex-col text-sm p-2 font-light max-w-xs">
            <p class="text-theme-secondary font-normal">Terms</p>
            <p class="text-theme-body">
              Your prompt payment is greatly appreciated.
            </p>
          </div>
        </div>
      </div> -->

      <!-- Invoice Footer -->
      <div class="inv-footer">
        <span
          >Medusa Distribution LLC 9 Woodland Rd., Suite D,<br />
          Roseland, NJ, 07068, US
        </span>
        <div class="info">
          <span>Email sales@medusadistribution.com</span>
          <span>Phone 973-808-1101</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
import Account from "@/modules/Account";
import { DownloadIcon } from "@heroicons/vue/outline";
export default {
  name: "Invoice",
  mixins: [Mymixin],
  components: {
    DownloadIcon,
  },
  methods: {
    downloadInvoice(order_number) {
      let temp = document.title;
      document.title = order_number;
      window.print();
      document.title = temp;
    },
    moment: function(date) {
      return moment(date).format("MMM Do YYYY");
    },
    getTotal(data) {
      let temp = 0;
      if (data)
        data.forEach(
          (element) =>
            (temp +=
              element.quantity * element.price +
              element.tax_rate_override / 100)
        );
      return temp;
    },
    getPrice(item) {
      if (item.discount) {
        return item.price - item.price * (item.discount / 100);
      }
      return item.price;
    },
    getTaxes(data) {
      let temp = 0;
      if (data)
        data.forEach((element) => (temp += element.tax_rate_override / 100));
      return temp;
    },
    getContent(inv, content, str = null) {
      let temp = content;
      for (let i = 0; i < inv.rules.length; i++) {
        if (
          str == "closed" &&
          inv.rules[i].tax_rule.includes("Closed Liquids") &&
          content != null
        ) {
          temp = content.replace("{sum of ml}", inv.rules[i].total);
          break;
        } else if (
          str == "open" &&
          inv.rules[i].tax_rule.includes("Open Liquids") &&
          content != null
        ) {
          temp = content.replace("{sum of ml}", inv.rules[i].total);
          break;
        } else if (str == "text" && content != null) {
          let total = inv.rules.reduce((a, b) => a + b.total, 0);
          temp = content.replace("{sum of ml}", total);
          break;
        } else if (content != null) {
          temp = content.replace("{sum of ml}", inv.rules[i].total);
        }
      }
      return temp != null && temp.includes("{sum of ml}") ? "" : temp;
    },
    packingList(inv){
      if(inv.packingList){
        this.$router.push('/packing-list/' + inv.order_id);
      }
    }
  },
  setup() {
    const { invoice, Invoice } = Account();
    Invoice();
    return {
      invoice,
    };
  },
};
</script>

<style>
@page {
  size: A4;
  margin: 15mm 0;
}

@page :first {
  margin-top: 0in;
}

@media print {
  html,
  body {
    @apply p-0 m-0 bg-white overflow-visible w-full;
    font-size: 11px;
    -webkit-print-color-adjust: exact;
    height: 282mm;
  }
  .hide-on-print {
    visibility: hidden;
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.invoice {
  @apply w-full h-full p-8 bg-theme-bg bg-opacity-30;

  @media print {
    @apply bg-white;
  }

  .inv-inner {
    @apply max-w-5xl mx-auto bg-white shadow-md;

    @media print {
      @apply max-w-full shadow-none;
      page-break-after: always;
    }
  }

  .inv-header {
    @apply w-full px-10 py-8 flex items-center justify-between;
    background-color: rgb(248, 248, 248);

    .inv-logo {
      @apply flex items-center space-x-1;

      span {
        @apply text-2xl leading-none font-bold text-theme-secondary uppercase;
      }
    }

    .inv-number {
      @apply flex items-center text-xs font-medium space-x-3;
      color: rgba(72, 90, 93, 0.54);
      span {
        min-width: 75px;
      }

      p {
        @apply text-sm;
        color: #485a5d;
      }
    }
  }

  .inv-icon {
    @apply w-full flex items-center justify-center pb-10 relative;

    span {
      @apply flex items-center justify-center w-14 h-14 border  rounded-full flex-shrink-0 bg-white -mt-7;
      border-color: #dbdbdb;

      svg {
        @apply w-9 h-9;
      }
    }
  }
  .badges {
    @apply flex w-full justify-end px-10 -mt-4 pb-1;
    .invoice-status {
      @apply inline-block border rounded-md border-gray-800 px-4 py-1 leading-none;

      span {
        @apply text-sm font-medium leading-none uppercase;
      }
    }
    .unpaid {
      @apply border-red-500 bg-red-500 text-red-600 bg-opacity-5;
    }
    .paid {
      @apply border-green-500 bg-green-500 text-green-600 bg-opacity-5;
    }
    .partial {
      @apply border-yellow-500 bg-yellow-500 text-yellow-600 bg-opacity-5;
    }
  }

  .inv-details {
    @apply flex items-start justify-between text-sm text-theme-body px-10 pb-8 leading-normal;

    .address-grid {
      @apply grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6;

      @media print {
        @apply grid grid-cols-2 gap-x-12 gap-y-6;
      }
    }

    .small {
      @apply inline-block text-xs text-theme-body uppercase font-normal mb-1.5;
    }

    .address {
      @apply flex flex-col space-y-7;

      > div {
        @apply w-full;
      }
      p {
        @apply text-theme-secondary mb-1;
      }
    }

    .details {
      @apply pt-5;
      .detail-item {
        @apply flex items-center py-1;

        span {
          @apply w-40;
        }

        p {
          @apply text-theme-secondary text-left;
        }
      }
    }
  }

  .inv-list {
    @media print {
      @apply px-10;
      page-break-inside: auto;
    }
    .inv-list-header {
      @apply flex items-center w-full px-10 py-4 border-b text-theme-body font-normal uppercase text-sm;
      border-color: rgba(180, 180, 180, 0.25);

      @media print {
        @apply px-0;
      }
    }
    .name {
      @apply flex-grow;
    }

    .price,
    .qty,
    .tax {
      @apply w-24;
    }

    .total {
      @apply w-24 text-center;
    }

    .inv-list-items {
      @apply w-full px-10 divide-y divide-gray-100;

      @media print {
        @apply px-0;
        page-break-inside: auto;
      }

      .item-info {
        @apply flex flex-col space-y-1.5 leading-none text-md font-medium;

        p {
          @apply text-theme-secondary text-md;

          @media print {
            font-size: 1rem;
          }
        }

        span {
          @apply text-theme-body text-sm;
        }
      }

      .inv-list-item {
        @apply flex items-center py-4 text-md text-theme-body font-normal;

        @media print {
          @apply py-3;
          page-break-inside: avoid;
          page-break-after: auto;
        }

        .name {
          @apply flex items-center space-x-4 text-md;

          img {
            @apply w-12 h-12;
          }
        }
        .total {
          @apply font-medium text-theme-dark text-md text-right;
        }

        @media print {
          .name,
          .total,
          .qty,
          .price {
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .inv-pricing {
    @apply grid grid-cols-2 py-5 px-10 mb-12 border-t gap-32;
    border-color: rgba(180, 180, 180, 0.25);

    @media print {
      page-break-inside: avoid;
      page-break-after: auto;
    }

    .order-pricings {
      @apply flex flex-col items-end;
    }

    .taxt-pricings {
      @apply flex flex-col items-start;
    }

    @media print {
      @apply px-5;
    }

    .price-item {
      @apply inline-flex items-center justify-between flex-shrink-0 w-full text-md text-theme-dark-shade font-light space-y-1.5;

      p {
        @apply w-3/5;
      }

      span {
        @apply w-2/5 text-right;
      }

      @media print {
        p,
        span {
          font-size: 1rem;
        }

        span {
          @apply text-right;
        }
      }

      &.highlight {
        @apply text-theme-secondary font-medium py-2 border-t mt-2;
        border-color: rgba(180, 180, 180, 0.25);

        &.no-border {
          @apply border-none py-0 mt-0;
        }
      }

      &.extra {
        @apply font-medium text-sm;
        color: #8fa3a9;
      }
    }
  }

  .inv-footer {
    @apply w-full px-10 py-6 border-t  flex items-start justify-between text-sm font-light text-theme-body leading-normal;
    border-color: rgba(180, 180, 180, 0.25);

    span {
      @apply block;
    }
  }
}
</style>
